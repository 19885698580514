@import 'src/styles/colors';

@mixin l-mobile {
  @media all and (max-width: 767px) {
    @content;
  }
}

body {
  color: $black;
  scroll-behavior: smooth;
}

html {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.pagination {
  align-items: center;
  color: $light-gray;
  display: flex;
  font-size: 16px;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
}

.pagination > li {
  margin: 0 12px;
}

.pagination > .active {
  color: $black;
  font-weight: bold;
}

.pagination__previous,
.pagination__next {
  align-items: center;
  background: $red-gradation;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.pagination__previous,
.pagination__next > a {
  color: $white !important;
}

.pagination__disabled {
  background: none;
  background-color: $shade;
  pointer-events: none;
}
