.rc-select-selector {
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  display: flex;
  height: 40px;
  padding: 16px;
}

.rc-select-selection-search-input {
  outline: none;
}

.rc-select-selection-overflow {
  display: flex;
}

.rc-select-selection-overflow-item {
  margin-right: 8px;
}

.rc-select-selection-item {
  background-color: #e2e8f0;
  border-radius: 4px;
  padding: 4px;
}

.rc-select-dropdown {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
}

.rc-select-dropdown-hidden {
  display: none;
}

.rc-select-selection-search {
  display: inline;
}

.rc-select-item {
  display: flex;
}
